import { render, staticRenderFns } from "./favorites.vue?vue&type=template&id=5ec99e0c&scoped=true"
import script from "./favorites.vue?vue&type=script&lang=js"
export * from "./favorites.vue?vue&type=script&lang=js"
import style0 from "./favorites.vue?vue&type=style&index=0&id=5ec99e0c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.37_css-loader@6.11.0_webpack@5.93.0__lodash@4.17.21__gvxpqcfmznag5rb2qochofdh3q/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ec99e0c",
  null
  
)

export default component.exports